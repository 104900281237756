<template>
  <div class="box">
    <div class="u-design">
      <div class="edit-box">
        <div class="edit-shop-content">
          <section class="edit-shop-bottomnav">
            <h3 style="padding: 10px 20px; font-size: 18px; color: #666; border-bottom: 1px solid #eee; margin-bottom: 20px;">商品推荐</h3>
            <el-form label-position="right" label-width="100px" size="small">
              <el-form-item label="推荐类型" prop="recommend">
                <el-radio-group v-model="content.recommend">
                  <el-radio-button :label="1">不推荐</el-radio-button>
                  <el-radio-button :label="2">后台默认推荐</el-radio-button>
                  <el-radio-button :label="3">自定义商品</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="" v-if="content.recommend==3">
                <el-button type="primary" size="small" @click="showModal=true">选择商品</el-button>
                <div class="goods-box">
                  <draggable v-model="content.goods" :options="traggabelOptions" :group="{ name: 'goods', pull: true, put: false }">
                  <div class="item" v-for="(item,index) in content.goods" :key="index">
                    <img :src="item.img" class="img" />
                    <div class="name">{{ item.title }}</div>
                    <div class="close" title="删除商品" @click="delGoods(index)"><i class="el-icon-error"></i></div>
                  </div>
                  </draggable>
                </div>
              </el-form-item>
            </el-form>
            <h3 style="padding: 10px 20px; font-size: 18px; color: #666; border-bottom: 1px solid #eee; margin-bottom: 20px;">我的服务</h3>
            <el-form label-position="right" label-width="100px" size="small">
              <el-form-item label="区块样式" prop="style">
                <el-radio-group v-model="content.style">
                  <el-radio-button :label="1">列表样式</el-radio-button>
                  <el-radio-button :label="2">图标样式</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <draggable v-model="content.data" :options="traggabelOptions" :group="{ name: 'menu', pull: false, put: false }">
            <div class="edit-item" v-for="(item, index) in content.data" :key="item.id">
              <el-form :model="item" label-width="140px" style="position: relative;" size="small">
                <span style="position: absolute; right: 10px; top: -25px; cursor: pointer; z-index: 1">
                  <el-popconfirm
                          title="您确认删除吗？"
                          @confirm="deleteNav(index)">
                    <el-button type="text" slot="reference">
                      <i class="el-icon-delete" style="font-size: 18px;" />
                    </el-button>
                  </el-popconfirm>
                </span>
                <span style="position: absolute; left: 0px; top: -30px; cursor: pointer; z-index: 1" title="长按拖拽">
                  <el-button type="text" slot="reference">
                      <i class="el-icon-rank" style="font-size: 24px;" />
                    </el-button>
                </span>
                <el-row style="height: 100px; margin-top: 10px; clear: both;">
                  <el-col :span="6">
                    <SelectImgs :selectNum="1" btnTitle="按钮图标" btnClass="w50" :selectData="[item.image]" :idx="index" :selectFunc="setImgUrl"></SelectImgs>
                  </el-col>
                  <el-col :span="18">
                    <div>
                      <el-row :gutter="10">
                        <el-col :span="4" style="line-height: 35px">
                          按钮名称
                        </el-col>
                        <el-col :span="16">
                          <el-input v-model="item.title" placeholder="请输入最多可输入5个字" maxlength="5" size="small"></el-input>
                        </el-col>
                      </el-row>
                    </div>
                    <div class="mg-tp-20">
                      <el-row :gutter="10">
                        <el-col :span="4" style="line-height: 35px">
                          链接地址
                        </el-col>
                        <el-col :span="16">
                          <select-link :setData="item.nav_link" :index="index" :setFunc="setNavFunc">
                            <template slot="button">
                              <el-input :value="item.nav_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                            </template>
                          </select-link>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            </draggable>
            <div style="overflow:hidden; margin-top: 20px; margin-left: 10px;">
              <el-button type="primary" size="small" @click="addNav" class="fl">添加菜单</el-button>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="saveBox">
      <el-button type="primary" size="small" @click="savePage">保存数据</el-button>
    </div>
    <el-dialog
        title="选择商品"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <goods-list :type="2" :selectFunc="chooseGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import selectLink from '../module/select.link.vue'
import draggable from 'vuedraggable';
import GoodsList from "@/components/Goods/GoodsList";
export default {
  components: {
    selectLink,
    draggable,
    SelectImgs,
    GoodsList
  },
  data() {
    return {
      showModal: false,
      source_domain: this.config.SOURCE_DOMAIN,
      content: {
        style: 1,
        recommend: 1,
        goods: [],
        data: []
      },
      defaultContent: {
        style: 1,
        recommend: 1,
        goods: [
        ],
        data: [
          {
            title: '积分中心', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/integral.png',
            nav_link: { // 导航链接
              authkey: 'integral',
              name: '积分中心',
              ctype: 2,
              path: 'pages/user/integral'
            }
          },
          {
            title: '我的余额', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/money.png',
            nav_link: { // 导航链接
              authkey: 'amount',
              name: '我的余额',
              ctype: 2,
              path: 'pages/user/amount'
            }
          },
          {
            title: '优惠券', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/coupon.png',
            nav_link: { // 导航链接
              authkey: 'member_coupon',
              name: '优惠券',
              ctype: 2,
              path: 'pages/user/coupon'
            }
          },
          {
            title: '我的地址', // 导航文字
            image: this.config.SOURCE_DOMAIN + '/images/fw/address.png',
            nav_link: { // 导航链接
              authkey: 'member_address',
              name: '我的地址',
              ctype: 2,
              path: 'pages/user/address'
            }
          }
        ]
      },
      traggabelOptions: {
        // group: {
        //   name: 'bottomNav',
        //   pull: true,
        //   put: true
        // },
        sort: true,
        animation: 100
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setImgUrl(item, idx) {
      this.content.data[idx].image = item.pic
    },
    setNavFunc(item, idx) {
      this.content.data[idx].nav_link = item
    },
    chooseGoods(goods) {
      var _this = this
      goods.forEach(function (item) {
        _this.content.goods.push({id: item.id, title: item.title, img: item.img, price: item.price})
      })
      _this.showModal = false
    },
    delGoods(idx) {
      this.content.goods.splice(idx,1)
    },
    addNav() {
      this.content.data.push({
        title: '菜单',
        image: '',// this.config.SOURCE_DOMAIN + '/images/fw/collection.png',
        nav_link: {
          type: '',
          name: '',
          id: ''
        }
      })
    },
    deleteNav(index) {
      this.content.data.splice(index,1)
    },
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.shop.designNavInfo({type:2}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          if(res.data) {
            that.content = res.data
          } else {
            that.content = that.defaultContent
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    savePage() {
      var that = this
      that.showLoading()
      var param = {
        type: 2,
        content: this.content
      }
      this.$api.shop.designNavEdit(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
  .u-design{min-height:578px; display: flex}
  .edit-box{margin-bottom: 70px;width: 750px}
  .edit-head h3{display:inline-block;margin:0 15px 0 0;font-weight:400;font-size:1pc;line-height:20px}
  .edit-shop-bottomnav {
    background-color: #fff;
  }
  .edit-shop-bottomnav {
    background-color: #fff;
  }
  .edit-shop-bottomnav .edit-tips{
    float:left;margin-left: 20px; margin-top: 1px;color: #999;
  }
  .edit-item{
    background-color: #f8f8f9;
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;
  }
</style>
